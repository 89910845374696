// footer
.footer {
  padding: 50px 0 0;
  background: $lightGray;

  .intro {
    margin-bottom: 30px;

    .logo {
      max-height: 230px;

      @include maxLgScreen {
        width: 120px;
      }
    }

    .social-icons {
      a {
        width: 35px;
        height: 35px;
        display: block;
        margin: 0 5px;
        text-align: center;
        line-height: 35px;
        color: inherit;
        border-radius: 50%;
        background: darken($lightGray, 5%);
        font-size: 17px;
        transition: all .5s;

        &:hover {
          background: $secondColor;
          color: #fff;
        }
      }
    }
  }

  .links {
    margin-bottom: 30px;

    h5 {
      font-size: 18px;
      margin-bottom: 20px;
      position: relative;
      display: inline-block;

      &::after {
        content: "";
        width: 60%;
        height: 5px;
        background: $secondColor;
        display: block;
        margin: 7px 0;
      }
    }

    a {
      color: #000;
      display: inline-block;
      margin-bottom: 12px;
      font-size: 14px;
      transition: all .5s;

      &:hover {
        color: $secondColor;
      }
    }
  }

  .copyright {
    background: $grayColor;
  }
}
