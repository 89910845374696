.btn {
    @extend %transition-2;
    font-weight: bold;
    text-transform: capitalize;
    padding: 13px 30px;
    border: none;
    border-radius: 6px;
    outline: none;
    position: relative;
    z-index: 1;
    &::before {
        @extend %transition-2;
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 50%;
        width: 0;
        z-index: -1;
        border-radius: 6px;
    }
    &:hover {
        &::before {
            width: 100%;
            left: 0;
        }
    }
    &--blue {
        background: $mainColor;
        color: #fff;

        &:hover {
            text-decoration: none;
            color: #fff;
            &::before {
                background: $secondColor;
            }
        }
    }
}