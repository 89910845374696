body {
  // height: 10000px;
  overflow-x: hidden;
}

a {
  display: inline-block;
  text-decoration: none;
}

img {
  max-width: 100%;
}

// scroll top btn & WhatsApp link
.scroll-top-btn,
.whatsapp-link {
  position: fixed;
  bottom: 25px;
  z-index: 99;
}

.scroll-top-btn {
  right: -200px;

  &.show {
    right: 20px;
  }
}

.whatsapp-link {
  background: #25D366;
  font-size: 25px;
  left: 20px;
  width: 45px;
  height: 45px;
  transition: transform .3s;

  &:hover {
    transform: scale(1.1);
  }
}

// banner section
.banner-section {
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 1;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba($mainColor, .5);
    z-index: -1;
  }
}
