%transition-2 {
  transition: all .2s;
}

.default-padding {
  padding: 70px 0;
}

.bg-gradient {
  background: rgb(70, 125, 228);
  background: linear-gradient(317deg, rgba(70, 125, 228, 1) 35%, rgba(79, 45, 163, 1) 100%);

  .btn:hover {
    color: $mainColor !important;

    &::before {
      background: #fff;
    }
  }
}

.section-heading {
  font-size: 35px;
  font-weight: bold;
  text-transform: uppercase;

  @include maxSmScreen {
    font-size: 30px;
  }
}
