.single-package {
  .home-slider {
    .image-wrapper {
      height: 340px;
      cursor: zoom-in;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .glide__slide .slide {
      min-height: 400px;

      &::after {
        display: none;
      }
    }
  }
}

.package-details {
  .desc-text {
    line-height: 1.9;
    font-size: 15px;
  }

  .package-features {
    .offer-text {
      background: #F9F3F3;
    }

    .list {
      background: #F2F7FB;

      li {
        border-bottom: 2px solid #fff;
      }
    }
  }

  .form {
    position: sticky;
    top: 0;
    width: 100%;
  }
}
