// home-slider
.home-slider {
  .glide__slide {
    transform: translate3d(0, 0, 0);

    .slide {
      min-height: 600px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      position: relative;
      z-index: 1;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba($mainColor, .5);
        z-index: -1;
      }

      &__heading {
        font-size: 50px;

        @include maxSmScreen {
          font-size: 34px;
        }
      }

      .lead {
        line-height: 1.8;

        @include maxSmScreen {
          font-size: 16px;
        }
      }
    }
  }

  .glide__bullet {
    width: 15px;
    height: 15px;
  }

  .glide__arrow {
    width: 40px;
    height: 40px;
    padding: 0;
    background: #fff;
    color: $mainColor;

    &:hover {
      background: transparent;
      color: #fff;
    }
  }
}

// features-section
.features-section {
  .card {
    box-shadow: 0px 5px 30px #7070704d;
    background: #fff;

    &__title {
      @include minSmScreen {
        font-size: 18px;
      }
    }

    img {
      transition: transform .2s;

      @include maxExScreen {
        max-height: 60px;
      }
    }

    &:hover {
      img {
        transform: scale(1.15);
      }
    }
  }
}

// packages-section
.packages-section {
  background-color: $lightGray;

  .card {
    position: relative;

    &__img {
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      height: 230px;
      transition: transform .5s;
    }

    &:hover {
      .card__img {
        transform: scale(1.1);
      }
    }

    &__details {
      i {
        color: $secondColor;
        font-size: 20px;
        display: inline-block;
        vertical-align: middle;
      }
    }

    &__link {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
}

// gallery
.gallery {
  &__img {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 200px;
    position: relative;

    @include maxMdScreen {
      height: 150px;
    }

    @include maxExScreen {
      height: 100px;

      i {
        font-size: 15px;
      }
    }

    &::after,
    i {
      transition: all .25s;
      transform: scale(0);
      opacity: 0;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba($secondColor, .5);
    }

    i {
      color: #fff;
      position: relative;
      z-index: 1;
    }

    &:hover {

      &::after,
      i {
        transform: scale(1);
        opacity: 1;
      }
    }
  }
}

// similar packages
.similar-packages {
  .glide__slides {
    white-space: initial;
  }

  .glide__arrow {
    background: #fff;
    color: $mainColor;
    font-size: 25px;
    padding: 5px;
    border: none;

    &--left {
      left: 5px;
    }

    &--right {
      right: 5px;
    }

    &.disabled {
      cursor: not-allowed;
      opacity: .5;
    }
  }

  .card {
    @include maxSmScreen {
      max-width: 400px;
      margin: auto;
    }
  }
}
