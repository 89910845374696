@import "./bootstrap-utilites-rtl.scss";
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Almarai:wght@400;700&display=swap');

@mixin directionStyle($left, $right) {

  /************************ header ************************/
  .header {
    .nav {
      .nav-item {
        @include minLgScreen {
          &:not(:last-of-type) {
            margin-#{$right}: 30px;
          }
        }

        &.dropdown {
          .dropdown-menu {
            #{$left}: auto;
            #{$right}: 0;
          }
        }
      }
    }
  }
}

//ltr style
body:not(.ar) {
  @include directionStyle(left, right);
  font-family: 'Open Sans', sans-serif;
}

//rtl style
body.ar {
  @include directionStyle(right, left);
  text-align: right;
  direction: rtl;
  font-family: 'Almarai', sans-serif;

  // bootstrap
  .list-unstyled {
    padding: 0;
  }
}
