.form {
	&__label {
		display: block;
		color: #4D4D4D;
		font-weight: 600;
	}
	&__input {
		@extend %transition-2;
		min-height: 48px;
		box-shadow: none !important;
		font-size: 14px;
		width: 100%;
		border: 1px solid #DEDEDE;
		outline: none;
		padding: 12px;
		border-radius: 5px;
		&:focus {
			border-color: $mainColor;
		}
	}
	&__sumbit-btn {
		padding: 14px 20px;
	}
}
// validated form
input.error-feedback,
textarea.error-feedback,
select.error-feedback {
	border-color: #f44336 !important;
	box-shadow: none !important;
}

div.error-feedback {
	color: #f44336;
	font-size: 12px;
	display: block;
	margin-bottom: 0;
	text-transform: none;
	animation: vibrate linear forwards .5s;
}

@keyframes vibrate {
	0% {
		transform: translateY(3px);
	}

	25% {
		transform: translateY(-3px);
	}

	50% {
		transform: translateY(3px);
	}

	75% {
		transform: translateY(-3px);
	}

	100% {
		transform: translateY(3px);
	}
}