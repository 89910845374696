.header {
  z-index: 999;
  padding: 6px 0;
  box-shadow: 0px 3px 6px #50505029;
  position: relative;

  .logo-image {
    max-width: 160px;
    max-height: 160px
  }

  .nav {
    .nav-item {
      @extend %transition-2;

      .nav-link {
        color: $darkColor;
        text-align: center;
        position: relative;
        padding: 8px 0;
        font-size: 14px;
        font-weight: 600;
        text-transform: uppercase;

        &::before {
          content: "";
          position: absolute;
          display: block;
          height: 3px;
          width: 0;
          left: 50%;
          transform: translateX(-50%);
          bottom: 0px;
          background: $mainColor;
          @extend %transition-2;
        }
      }

      &.dropdown {
        &:hover {
          .dropdown-menu {
            display: block;
            top: 90%;
            max-width: 250px;
            margin: auto;
            float: none;
            max-height: 180px;
            overflow-y: auto;

            @include maxMdScreen {
              position: relative;
            }
          }
        }

        .nav-link {
          &::before {
            display: none;
          }
        }

        .dropdown-item.active {
          color: $mainColor;
          background: $lightGray;
        }
      }

      &:hover,
      &.active {
        .nav-link {
          color: $mainColor;
          text-decoration: none;

          &::before {
            width: 100%;
          }
        }
      }
    }
  }


  .toggle-nav-icon {
    cursor: pointer;

    .line {
      @extend %transition-2;
      width: 35px;
      height: 3px;
      background: $mainColor;

      &:not(:last-of-type) {
        margin: 9px 0;
      }
    }

    &.open {
      .line {
        &:first-of-type {
          transform: rotate(-45deg) translate(-8px, 5px);
        }

        &:nth-of-type(2) {
          opacity: 0;
        }

        &:last-of-type {
          transform: rotate(-131deg) translate(12px, 7px);
        }
      }
    }
  }

  @include maxMdScreen {
    padding: 6px 0 !important;
    box-shadow: 0 0 10px lightgrey;
    min-height: auto;

    .nav {
      padding: 20px 10px !important;
      position: absolute;
      top: 100%;
      background: #fff;
      left: 0;
      border-top: 1px solid lightgrey;
      border-bottom: 1px solid lightgrey;
      opacity: 0;
      pointer-events: none;
      width: 100%;

      &.show {
        pointer-events: unset;
        opacity: 1;
      }

      .nav-item {
        text-align: center;

        &:not(:last-of-type) {
          margin-bottom: 20px;
        }
      }

    }
  }
}
