.ar .m-0 {
    margin: 0 !important; }

.ar .mx-0 {
margin-right: 0 !important;
margin-left: 0 !important; }

.ar .mr-0 {
margin-left: 0 !important;
margin-right: unset !important; }

.ar .mx-0 {
margin-left: 0 !important;
margin-right: 0 !important; }

.ar .ml-0 {
margin-right: 0 !important;
margin-left: unset !important; }

.ar .m-1 {
margin: 0.25rem !important; }

.ar .mx-1 {
margin-right: 0.25rem !important;
margin-left: 0.25rem !important; }

.ar .mr-1 {
margin-left: 0.25rem !important;
margin-right: unset !important; }

.ar .mx-1 {
margin-left: 0.25rem !important;
margin-right: 0.25rem !important; }

.ar .ml-1 {
margin-right: 0.25rem !important;
margin-left: unset !important; }

.ar .m-2 {
margin: 0.5rem !important; }

.ar .mx-2 {
margin-right: 0.5rem !important;
margin-left: 0.5rem !important; }

.ar .mr-2 {
margin-left: 0.5rem !important;
margin-right: unset !important; }

.ar .mx-2 {
margin-left: 0.5rem !important;
margin-right: 0.5rem !important; }

.ar .ml-2 {
margin-right: 0.5rem !important;
margin-left: unset !important; }

.ar .m-3 {
margin: 1rem !important; }

.ar .mx-3 {
margin-right: 1rem !important;
margin-left: 1rem !important; }

.ar .mr-3 {
margin-left: 1rem !important;
margin-right: unset !important; }

.ar .mx-3 {
margin-left: 1rem !important;
margin-right: 1rem !important; }

.ar .ml-3 {
margin-right: 1rem !important;
margin-left: unset !important; }

.ar .m-4 {
margin: 1.5rem !important; }

.ar .mx-4 {
margin-right: 1.5rem !important;
margin-left: 1.5rem !important; }

.ar .mr-4 {
margin-left: 1.5rem !important;
margin-right: unset !important; }

.ar .mx-4 {
margin-left: 1.5rem !important;
margin-right: 1.5rem !important; }

.ar .ml-4 {
margin-right: 1.5rem !important;
margin-left: unset !important; }

.ar .m-5 {
margin: 3rem !important; }

.ar .mx-5 {
margin-right: 3rem !important;
margin-left: 3rem !important; }

.ar .mr-5 {
margin-left: 3rem !important;
margin-right: unset !important; }

.ar .mx-5 {
margin-left: 3rem !important;
margin-right: 3rem !important; }

.ar .ml-5 {
margin-right: 3rem !important;
margin-left: unset !important; }

.ar .p-0 {
padding: 0 !important; }

.ar .px-0 {
padding-right: 0 !important;
padding-left: 0 !important; }

.ar .pr-0 {
padding-left: 0 !important;
padding-right: unset !important; }

.ar .px-0 {
padding-left: 0 !important;
padding-right: 0 !important; }

.ar .pl-0 {
padding-right: 0 !important;
padding-left: unset !important; }

.ar .p-1 {
padding: 0.25rem !important; }

.ar .px-1 {
padding-right: 0.25rem !important;
padding-left: 0.25rem !important; }

.ar .pr-1 {
padding-left: 0.25rem !important;
padding-right: unset !important; }

.ar .px-1 {
padding-left: 0.25rem !important;
padding-right: 0.25rem !important; }

.ar .pl-1 {
padding-right: 0.25rem !important;
padding-left: unset !important; }

.ar .p-2 {
padding: 0.5rem !important; }

.ar .px-2 {
padding-right: 0.5rem !important;
padding-left: 0.5rem !important; }

.ar .pr-2 {
padding-left: 0.5rem !important;
padding-right: unset !important; }

.ar .px-2 {
padding-left: 0.5rem !important;
padding-right: 0.5rem !important; }

.ar .pl-2 {
padding-right: 0.5rem !important;
padding-left: unset !important; }

.ar .p-3 {
padding: 1rem !important; }

.ar .px-3 {
padding-right: 1rem !important;
padding-left: 1rem !important; }

.ar .pr-3 {
padding-left: 1rem !important;
padding-right: unset !important; }

.ar .px-3 {
padding-left: 1rem !important;
padding-right: 1rem !important; }

.ar .pl-3 {
padding-right: 1rem !important;
padding-left: unset !important; }

.ar .p-4 {
padding: 1.5rem !important; }

.ar .px-4 {
padding-right: 1.5rem !important;
padding-left: 1.5rem !important; }

.ar .pr-4 {
padding-left: 1.5rem !important;
padding-right: unset !important; }

.ar .px-4 {
padding-left: 1.5rem !important;
padding-right: 1.5rem !important; }

.ar .pl-4 {
padding-right: 1.5rem !important;
padding-left: unset !important; }

.ar .p-5 {
padding: 3rem !important; }

.ar .px-5 {
padding-right: 3rem !important;
padding-left: 3rem !important; }

.ar .pr-5 {
padding-left: 3rem !important;
padding-right: unset !important; }

.ar .px-5 {
padding-left: 3rem !important;
padding-right: 3rem !important; }

.ar .pl-5 {
padding-right: 3rem !important;
padding-left: unset !important; }

.ar .m-n1 {
margin: -0.25rem !important; }

.ar .mx-n1 {
margin-right: -0.25rem !important;
margin-left: -0.25rem !important; }

.ar .mr-n1 {
margin-left: -0.25rem !important;
margin-right: unset !important; }

.ar .ml-n1 {
margin-right: -0.25rem !important;
margin-left: unset !important; }

.ar .m-n2 {
margin: -0.5rem !important; }

.ar .mx-n2 {
margin-right: -0.5rem !important;
margin-left: -0.5rem !important; }

.ar .mr-n2 {
margin-left: -0.5rem !important;
margin-right: unset !important; }

.ar .ml-n2 {
margin-right: -0.5rem !important;
margin-left: unset !important; }

.ar .m-n3 {
margin: -1rem !important; }

.ar .mx-n3 {
margin-right: -1rem !important;
margin-left: -1rem !important; }

.ar .mr-n3 {
margin-left: -1rem !important;
margin-right: unset !important; }

.ar .ml-n3 {
margin-right: -1rem !important;
margin-left: unset !important; }

.ar .m-n4 {
margin: -1.5rem !important; }

.ar .mx-n4 {
margin-right: -1.5rem !important;
margin-left: -1.5rem !important; }

.ar .mr-n4 {
margin-left: -1.5rem !important;
margin-right: unset !important; }

.ar .ml-n4 {
margin-right: -1.5rem !important;
margin-left: unset !important; }

.ar .m-n5 {
margin: -3rem !important; }

.ar .mx-n5 {
margin-right: -3rem !important;
margin-left: -3rem !important; }

.ar .mr-n5 {
margin-left: -3rem !important;
margin-right: unset !important; }

.ar .ml-n5 {
margin-right: -3rem !important;
margin-left: unset !important; }

.ar .m-auto {
margin: auto !important; }

.ar .mx-auto {
margin-right: auto !important;
margin-left: auto !important; }

.ar .mr-auto {
margin-left: auto !important;
margin-right: unset !important; }

.ar .ml-auto {
margin-right: auto !important;
margin-left: unset !important; }

@media (min-width: 576px) {
.ar .m-sm-0 {
    margin: 0 !important; }
.ar .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
.ar .mr-sm-0 {
    margin-left: 0 !important;
    margin-right: unset !important; }
.ar .mx-sm-0 {
    margin-left: 0 !important;
    margin-right: 0 !important; }
.ar .ml-sm-0 {
    margin-right: 0 !important;
    margin-left: unset !important; }
.ar .m-sm-1 {
    margin: 0.25rem !important; }
.ar .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
.ar .mr-sm-1 {
    margin-left: 0.25rem !important;
    margin-right: unset !important; }
.ar .mx-sm-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important; }
.ar .ml-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: unset !important; }
.ar .m-sm-2 {
    margin: 0.5rem !important; }
.ar .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
.ar .mr-sm-2 {
    margin-left: 0.5rem !important;
    margin-right: unset !important; }
.ar .mx-sm-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important; }
.ar .ml-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: unset !important; }
.ar .m-sm-3 {
    margin: 1rem !important; }
.ar .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
.ar .mr-sm-3 {
    margin-left: 1rem !important;
    margin-right: unset !important; }
.ar .mx-sm-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important; }
.ar .ml-sm-3 {
    margin-right: 1rem !important;
    margin-left: unset !important; }
.ar .m-sm-4 {
    margin: 1.5rem !important; }
.ar .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
.ar .mr-sm-4 {
    margin-left: 1.5rem !important;
    margin-right: unset !important; }
.ar .mx-sm-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important; }
.ar .ml-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: unset !important; }
.ar .m-sm-5 {
    margin: 3rem !important; }
.ar .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
.ar .mr-sm-5 {
    margin-left: 3rem !important;
    margin-right: unset !important; }
.ar .mx-sm-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important; }
.ar .ml-sm-5 {
    margin-right: 3rem !important;
    margin-left: unset !important; }
.ar .p-sm-0 {
    padding: 0 !important; }
.ar .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
.ar .pr-sm-0 {
    padding-left: 0 !important;
    padding-right: unset !important; }
.ar .px-sm-0 {
    padding-left: 0 !important;
    padding-right: 0 !important; }
.ar .pl-sm-0 {
    padding-right: 0 !important;
    padding-left: unset !important; }
.ar .p-sm-1 {
    padding: 0.25rem !important; }
.ar .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
.ar .pr-sm-1 {
    padding-left: 0.25rem !important;
    padding-right: unset !important; }
.ar .px-sm-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important; }
.ar .pl-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: unset !important; }
.ar .p-sm-2 {
    padding: 0.5rem !important; }
.ar .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
.ar .pr-sm-2 {
    padding-left: 0.5rem !important;
    padding-right: unset !important; }
.ar .px-sm-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important; }
.ar .pl-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: unset !important; }
.ar .p-sm-3 {
    padding: 1rem !important; }
.ar .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
.ar .pr-sm-3 {
    padding-left: 1rem !important;
    padding-right: unset !important; }
.ar .px-sm-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important; }
.ar .pl-sm-3 {
    padding-right: 1rem !important;
    padding-left: unset !important; }
.ar .p-sm-4 {
    padding: 1.5rem !important; }
.ar .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
.ar .pr-sm-4 {
    padding-left: 1.5rem !important;
    padding-right: unset !important; }
.ar .px-sm-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important; }
.ar .pl-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: unset !important; }
.ar .p-sm-5 {
    padding: 3rem !important; }
.ar .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
.ar .pr-sm-5 {
    padding-left: 3rem !important;
    padding-right: unset !important; }
.ar .px-sm-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important; }
.ar .pl-sm-5 {
    padding-right: 3rem !important;
    padding-left: unset !important; }
.ar .m-sm-n1 {
    margin: -0.25rem !important; }
.ar .mx-sm-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important; }
.ar .mr-sm-n1 {
    margin-left: -0.25rem !important;
    margin-right: unset !important; }
.ar .ml-sm-n1 {
    margin-right: -0.25rem !important;
    margin-left: unset !important; }
.ar .m-sm-n2 {
    margin: -0.5rem !important; }
.ar .mx-sm-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important; }
.ar .mr-sm-n2 {
    margin-left: -0.5rem !important;
    margin-right: unset !important; }
.ar .ml-sm-n2 {
    margin-right: -0.5rem !important;
    margin-left: unset !important; }
.ar .m-sm-n3 {
    margin: -1rem !important; }
.ar .mx-sm-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important; }
.ar .mr-sm-n3 {
    margin-left: -1rem !important;
    margin-right: unset !important; }
.ar .ml-sm-n3 {
    margin-right: -1rem !important;
    margin-left: unset !important; }
.ar .m-sm-n4 {
    margin: -1.5rem !important; }
.ar .mx-sm-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important; }
.ar .mr-sm-n4 {
    margin-left: -1.5rem !important;
    margin-right: unset !important; }
.ar .ml-sm-n4 {
    margin-right: -1.5rem !important;
    margin-left: unset !important; }
.ar .m-sm-n5 {
    margin: -3rem !important; }
.ar .mx-sm-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important; }
.ar .mr-sm-n5 {
    margin-left: -3rem !important;
    margin-right: unset !important; }
.ar .ml-sm-n5 {
    margin-right: -3rem !important;
    margin-left: unset !important; }
.ar .m-sm-auto {
    margin: auto !important; }
.ar .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
.ar .mr-sm-auto {
    margin-left: auto !important;
    margin-right: unset !important; }
.ar .ml-sm-auto {
    margin-right: auto !important;
    margin-left: unset !important; } }

@media (min-width: 768px) {
.ar .m-md-0 {
    margin: 0 !important; }
.ar .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
.ar .mr-md-0 {
    margin-left: 0 !important;
    margin-right: unset !important; }
.ar .mx-md-0 {
    margin-left: 0 !important;
    margin-right: 0 !important; }
.ar .ml-md-0 {
    margin-right: 0 !important;
    margin-left: unset !important; }
.ar .m-md-1 {
    margin: 0.25rem !important; }
.ar .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
.ar .mr-md-1 {
    margin-left: 0.25rem !important;
    margin-right: unset !important; }
.ar .mx-md-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important; }
.ar .ml-md-1 {
    margin-right: 0.25rem !important;
    margin-left: unset !important; }
.ar .m-md-2 {
    margin: 0.5rem !important; }
.ar .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
.ar .mr-md-2 {
    margin-left: 0.5rem !important;
    margin-right: unset !important; }
.ar .mx-md-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important; }
.ar .ml-md-2 {
    margin-right: 0.5rem !important;
    margin-left: unset !important; }
.ar .m-md-3 {
    margin: 1rem !important; }
.ar .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
.ar .mr-md-3 {
    margin-left: 1rem !important;
    margin-right: unset !important; }
.ar .mx-md-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important; }
.ar .ml-md-3 {
    margin-right: 1rem !important;
    margin-left: unset !important; }
.ar .m-md-4 {
    margin: 1.5rem !important; }
.ar .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
.ar .mr-md-4 {
    margin-left: 1.5rem !important;
    margin-right: unset !important; }
.ar .mx-md-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important; }
.ar .ml-md-4 {
    margin-right: 1.5rem !important;
    margin-left: unset !important; }
.ar .m-md-5 {
    margin: 3rem !important; }
.ar .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
.ar .mr-md-5 {
    margin-left: 3rem !important;
    margin-right: unset !important; }
.ar .mx-md-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important; }
.ar .ml-md-5 {
    margin-right: 3rem !important;
    margin-left: unset !important; }
.ar .p-md-0 {
    padding: 0 !important; }
.ar .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
.ar .pr-md-0 {
    padding-left: 0 !important;
    padding-right: unset !important; }
.ar .px-md-0 {
    padding-left: 0 !important;
    padding-right: 0 !important; }
.ar .pl-md-0 {
    padding-right: 0 !important;
    padding-left: unset !important; }
.ar .p-md-1 {
    padding: 0.25rem !important; }
.ar .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
.ar .pr-md-1 {
    padding-left: 0.25rem !important;
    padding-right: unset !important; }
.ar .px-md-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important; }
.ar .pl-md-1 {
    padding-right: 0.25rem !important;
    padding-left: unset !important; }
.ar .p-md-2 {
    padding: 0.5rem !important; }
.ar .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
.ar .pr-md-2 {
    padding-left: 0.5rem !important;
    padding-right: unset !important; }
.ar .px-md-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important; }
.ar .pl-md-2 {
    padding-right: 0.5rem !important;
    padding-left: unset !important; }
.ar .p-md-3 {
    padding: 1rem !important; }
.ar .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
.ar .pr-md-3 {
    padding-left: 1rem !important;
    padding-right: unset !important; }
.ar .px-md-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important; }
.ar .pl-md-3 {
    padding-right: 1rem !important;
    padding-left: unset !important; }
.ar .p-md-4 {
    padding: 1.5rem !important; }
.ar .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
.ar .pr-md-4 {
    padding-left: 1.5rem !important;
    padding-right: unset !important; }
.ar .px-md-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important; }
.ar .pl-md-4 {
    padding-right: 1.5rem !important;
    padding-left: unset !important; }
.ar .p-md-5 {
    padding: 3rem !important; }
.ar .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
.ar .pr-md-5 {
    padding-left: 3rem !important;
    padding-right: unset !important; }
.ar .px-md-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important; }
.ar .pl-md-5 {
    padding-right: 3rem !important;
    padding-left: unset !important; }
.ar .m-md-n1 {
    margin: -0.25rem !important; }
.ar .mx-md-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important; }
.ar .mr-md-n1 {
    margin-left: -0.25rem !important;
    margin-right: unset !important; }
.ar .ml-md-n1 {
    margin-right: -0.25rem !important;
    margin-left: unset !important; }
.ar .m-md-n2 {
    margin: -0.5rem !important; }
.ar .mx-md-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important; }
.ar .mr-md-n2 {
    margin-left: -0.5rem !important;
    margin-right: unset !important; }
.ar .ml-md-n2 {
    margin-right: -0.5rem !important;
    margin-left: unset !important; }
.ar .m-md-n3 {
    margin: -1rem !important; }
.ar .mx-md-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important; }
.ar .mr-md-n3 {
    margin-left: -1rem !important;
    margin-right: unset !important; }
.ar .ml-md-n3 {
    margin-right: -1rem !important;
    margin-left: unset !important; }
.ar .m-md-n4 {
    margin: -1.5rem !important; }
.ar .mx-md-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important; }
.ar .mr-md-n4 {
    margin-left: -1.5rem !important;
    margin-right: unset !important; }
.ar .ml-md-n4 {
    margin-right: -1.5rem !important;
    margin-left: unset !important; }
.ar .m-md-n5 {
    margin: -3rem !important; }
.ar .mx-md-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important; }
.ar .mr-md-n5 {
    margin-left: -3rem !important;
    margin-right: unset !important; }
.ar .ml-md-n5 {
    margin-right: -3rem !important;
    margin-left: unset !important; }
.ar .m-md-auto {
    margin: auto !important; }
.ar .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
.ar .mr-md-auto {
    margin-left: auto !important;
    margin-right: unset !important; }
.ar .ml-md-auto {
    margin-right: auto !important;
    margin-left: unset !important; } }

@media (min-width: 992px) {
.ar .m-lg-0 {
    margin: 0 !important; }
.ar .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
.ar .mr-lg-0 {
    margin-left: 0 !important;
    margin-right: unset !important; }
.ar .mx-lg-0 {
    margin-left: 0 !important;
    margin-right: 0 !important; }
.ar .ml-lg-0 {
    margin-right: 0 !important;
    margin-left: unset !important; }
.ar .m-lg-1 {
    margin: 0.25rem !important; }
.ar .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
.ar .mr-lg-1 {
    margin-left: 0.25rem !important;
    margin-right: unset !important; }
.ar .mx-lg-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important; }
.ar .ml-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: unset !important; }
.ar .m-lg-2 {
    margin: 0.5rem !important; }
.ar .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
.ar .mr-lg-2 {
    margin-left: 0.5rem !important;
    margin-right: unset !important; }
.ar .mx-lg-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important; }
.ar .ml-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: unset !important; }
.ar .m-lg-3 {
    margin: 1rem !important; }
.ar .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
.ar .mr-lg-3 {
    margin-left: 1rem !important;
    margin-right: unset !important; }
.ar .mx-lg-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important; }
.ar .ml-lg-3 {
    margin-right: 1rem !important;
    margin-left: unset !important; }
.ar .m-lg-4 {
    margin: 1.5rem !important; }
.ar .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
.ar .mr-lg-4 {
    margin-left: 1.5rem !important;
    margin-right: unset !important; }
.ar .mx-lg-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important; }
.ar .ml-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: unset !important; }
.ar .m-lg-5 {
    margin: 3rem !important; }
.ar .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
.ar .mr-lg-5 {
    margin-left: 3rem !important;
    margin-right: unset !important; }
.ar .mx-lg-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important; }
.ar .ml-lg-5 {
    margin-right: 3rem !important;
    margin-left: unset !important; }
.ar .p-lg-0 {
    padding: 0 !important; }
.ar .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
.ar .pr-lg-0 {
    padding-left: 0 !important;
    padding-right: unset !important; }
.ar .px-lg-0 {
    padding-left: 0 !important;
    padding-right: 0 !important; }
.ar .pl-lg-0 {
    padding-right: 0 !important;
    padding-left: unset !important; }
.ar .p-lg-1 {
    padding: 0.25rem !important; }
.ar .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
.ar .pr-lg-1 {
    padding-left: 0.25rem !important;
    padding-right: unset !important; }
.ar .px-lg-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important; }
.ar .pl-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: unset !important; }
.ar .p-lg-2 {
    padding: 0.5rem !important; }
.ar .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
.ar .pr-lg-2 {
    padding-left: 0.5rem !important;
    padding-right: unset !important; }
.ar .px-lg-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important; }
.ar .pl-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: unset !important; }
.ar .p-lg-3 {
    padding: 1rem !important; }
.ar .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
.ar .pr-lg-3 {
    padding-left: 1rem !important;
    padding-right: unset !important; }
.ar .px-lg-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important; }
.ar .pl-lg-3 {
    padding-right: 1rem !important;
    padding-left: unset !important; }
.ar .p-lg-4 {
    padding: 1.5rem !important; }
.ar .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
.ar .pr-lg-4 {
    padding-left: 1.5rem !important;
    padding-right: unset !important; }
.ar .px-lg-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important; }
.ar .pl-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: unset !important; }
.ar .p-lg-5 {
    padding: 3rem !important; }
.ar .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
.ar .pr-lg-5 {
    padding-left: 3rem !important;
    padding-right: unset !important; }
.ar .px-lg-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important; }
.ar .pl-lg-5 {
    padding-right: 3rem !important;
    padding-left: unset !important; }
.ar .m-lg-n1 {
    margin: -0.25rem !important; }
.ar .mx-lg-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important; }
.ar .mr-lg-n1 {
    margin-left: -0.25rem !important;
    margin-right: unset !important; }
.ar .ml-lg-n1 {
    margin-right: -0.25rem !important;
    margin-left: unset !important; }
.ar .m-lg-n2 {
    margin: -0.5rem !important; }
.ar .mx-lg-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important; }
.ar .mr-lg-n2 {
    margin-left: -0.5rem !important;
    margin-right: unset !important; }
.ar .ml-lg-n2 {
    margin-right: -0.5rem !important;
    margin-left: unset !important; }
.ar .m-lg-n3 {
    margin: -1rem !important; }
.ar .mx-lg-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important; }
.ar .mr-lg-n3 {
    margin-left: -1rem !important;
    margin-right: unset !important; }
.ar .ml-lg-n3 {
    margin-right: -1rem !important;
    margin-left: unset !important; }
.ar .m-lg-n4 {
    margin: -1.5rem !important; }
.ar .mx-lg-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important; }
.ar .mr-lg-n4 {
    margin-left: -1.5rem !important;
    margin-right: unset !important; }
.ar .ml-lg-n4 {
    margin-right: -1.5rem !important;
    margin-left: unset !important; }
.ar .m-lg-n5 {
    margin: -3rem !important; }
.ar .mx-lg-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important; }
.ar .mr-lg-n5 {
    margin-left: -3rem !important;
    margin-right: unset !important; }
.ar .ml-lg-n5 {
    margin-right: -3rem !important;
    margin-left: unset !important; }
.ar .m-lg-auto {
    margin: auto !important; }
.ar .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
.ar .mr-lg-auto {
    margin-left: auto !important;
    margin-right: unset !important; }
.ar .ml-lg-auto {
    margin-right: auto !important;
    margin-left: unset !important; } }

@media (min-width: 1200px) {
.ar .m-xl-0 {
    margin: 0 !important; }
.ar .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
.ar .mr-xl-0 {
    margin-left: 0 !important;
    margin-right: unset !important; }
.ar .mx-xl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important; }
.ar .ml-xl-0 {
    margin-right: 0 !important;
    margin-left: unset !important; }
.ar .m-xl-1 {
    margin: 0.25rem !important; }
.ar .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
.ar .mr-xl-1 {
    margin-left: 0.25rem !important;
    margin-right: unset !important; }
.ar .mx-xl-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important; }
.ar .ml-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: unset !important; }
.ar .m-xl-2 {
    margin: 0.5rem !important; }
.ar .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
.ar .mr-xl-2 {
    margin-left: 0.5rem !important;
    margin-right: unset !important; }
.ar .mx-xl-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important; }
.ar .ml-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: unset !important; }
.ar .m-xl-3 {
    margin: 1rem !important; }
.ar .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
.ar .mr-xl-3 {
    margin-left: 1rem !important;
    margin-right: unset !important; }
.ar .mx-xl-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important; }
.ar .ml-xl-3 {
    margin-right: 1rem !important;
    margin-left: unset !important; }
.ar .m-xl-4 {
    margin: 1.5rem !important; }
.ar .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
.ar .mr-xl-4 {
    margin-left: 1.5rem !important;
    margin-right: unset !important; }
.ar .mx-xl-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important; }
.ar .ml-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: unset !important; }
.ar .m-xl-5 {
    margin: 3rem !important; }
.ar .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
.ar .mr-xl-5 {
    margin-left: 3rem !important;
    margin-right: unset !important; }
.ar .mx-xl-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important; }
.ar .ml-xl-5 {
    margin-right: 3rem !important;
    margin-left: unset !important; }
.ar .p-xl-0 {
    padding: 0 !important; }
.ar .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
.ar .pr-xl-0 {
    padding-left: 0 !important;
    padding-right: unset !important; }
.ar .px-xl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important; }
.ar .pl-xl-0 {
    padding-right: 0 !important;
    padding-left: unset !important; }
.ar .p-xl-1 {
    padding: 0.25rem !important; }
.ar .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
.ar .pr-xl-1 {
    padding-left: 0.25rem !important;
    padding-right: unset !important; }
.ar .px-xl-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important; }
.ar .pl-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: unset !important; }
.ar .p-xl-2 {
    padding: 0.5rem !important; }
.ar .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
.ar .pr-xl-2 {
    padding-left: 0.5rem !important;
    padding-right: unset !important; }
.ar .px-xl-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important; }
.ar .pl-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: unset !important; }
.ar .p-xl-3 {
    padding: 1rem !important; }
.ar .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
.ar .pr-xl-3 {
    padding-left: 1rem !important;
    padding-right: unset !important; }
.ar .px-xl-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important; }
.ar .pl-xl-3 {
    padding-right: 1rem !important;
    padding-left: unset !important; }
.ar .p-xl-4 {
    padding: 1.5rem !important; }
.ar .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
.ar .pr-xl-4 {
    padding-left: 1.5rem !important;
    padding-right: unset !important; }
.ar .px-xl-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important; }
.ar .pl-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: unset !important; }
.ar .p-xl-5 {
    padding: 3rem !important; }
.ar .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
.ar .pr-xl-5 {
    padding-left: 3rem !important;
    padding-right: unset !important; }
.ar .px-xl-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important; }
.ar .pl-xl-5 {
    padding-right: 3rem !important;
    padding-left: unset !important; }
.ar .m-xl-n1 {
    margin: -0.25rem !important; }
.ar .mx-xl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important; }
.ar .mr-xl-n1 {
    margin-left: -0.25rem !important;
    margin-right: unset !important; }
.ar .ml-xl-n1 {
    margin-right: -0.25rem !important;
    margin-left: unset !important; }
.ar .m-xl-n2 {
    margin: -0.5rem !important; }
.ar .mx-xl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important; }
.ar .mr-xl-n2 {
    margin-left: -0.5rem !important;
    margin-right: unset !important; }
.ar .ml-xl-n2 {
    margin-right: -0.5rem !important;
    margin-left: unset !important; }
.ar .m-xl-n3 {
    margin: -1rem !important; }
.ar .mx-xl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important; }
.ar .mr-xl-n3 {
    margin-left: -1rem !important;
    margin-right: unset !important; }
.ar .ml-xl-n3 {
    margin-right: -1rem !important;
    margin-left: unset !important; }
.ar .m-xl-n4 {
    margin: -1.5rem !important; }
.ar .mx-xl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important; }
.ar .mr-xl-n4 {
    margin-left: -1.5rem !important;
    margin-right: unset !important; }
.ar .ml-xl-n4 {
    margin-right: -1.5rem !important;
    margin-left: unset !important; }
.ar .m-xl-n5 {
    margin: -3rem !important; }
.ar .mx-xl-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important; }
.ar .mr-xl-n5 {
    margin-left: -3rem !important;
    margin-right: unset !important; }
.ar .ml-xl-n5 {
    margin-right: -3rem !important;
    margin-left: unset !important; }
.ar .m-xl-auto {
    margin: auto !important; }
.ar .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
.ar .mr-xl-auto {
    margin-left: auto !important;
    margin-right: unset !important; }
.ar .ml-xl-auto {
    margin-right: auto !important;
    margin-left: unset !important; } }